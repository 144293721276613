@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Comfortaa:300,400,700");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Righteous&display=swap");

body {
  margin: 0;
  font-family: "Comfortaa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .shallow-text {
    color: transparent;
    -webkit-text-stroke: 2px rgb(0, 0, 0);
    line-height: inherit;
  }

  .clone {
    pointer-events: none;
    /* color: black; */
    clip-path: inset(0 100% 0 0);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    line-height: inherit;
  }
}

.shallow-text:hover ~ .clone {
  clip-path: inset(0 0 0 0) !important;
}
